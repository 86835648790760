@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --themeBg: #e9eff6;
}

body {
  /* max-width: 1200px; */
  margin: auto;
  font-family: "Poppins";
  font-style: normal;
  position: relative;
  font-size: 13px;
}

p {
  font-size: 13px;
}
.text-underline {
  text-decoration: underline !important;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  font-family: "Poppins";
  font-style: normal;
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  background-color: #0000001a;
}

::-webkit-scrollbar-thumb {
  background-color: #0000001a;
  border-radius: 30px;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.bg-theme {
  background-color: #e9eff6;
}

.divider {
  background-color: #0000001a;
  /* border: 1px solid #0000001a; */
}

.blur-screen {
  position: absolute;
  width: 100%;
  left: 0;
  height: 100%;
  z-index: 9999;
  background-color: #00000041;
  bottom: 0;
}

.vip,
.premium-box {
  border: 1px solid #fff;
  padding: 0px 12px;
  border-radius: 12px;
  background: #007aff;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  width: 80px;
  letter-spacing: -0.408px;
  color: #ffffff;
  cursor: default;
}

/* .subcription-section .d-flex.ml-auto.space-between.w-75{
    width: 100% !important;
}
.subcription-section .d-flex.w-75.space-between.ml-auto{
    width: 100% !important;
} */

.premium-box {
  color: unset;
  background: #ffcb3f;
}

.backBar {
  height: 72px;
  padding: 8px 16px;
  margin-bottom: 22px;
}

.menu-label {
  white-space: nowrap;
  font-size: 13px;
  cursor: pointer;
}

.common-footer-container {
  background-color: #0d3068;
  /* height: 300px; */
  padding: 30px;
}

/* ::-webkit-slider-thumb {
    background-color: #e9be50;
} */

.row {
  margin: 0;
  width: 100%;
}

.main-container {
  background: #e9eff6;
  height: 100vh;
  overflow-y: auto;
  /* padding-bottom: 70px; */
}

.wrapped-content-area {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.horizontal-center {
  display: flex;
  align-items: center;
}

.vertical-center {
  display: flex;
  justify-content: center;
}

.mob-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #444444;
}

.text {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.408px;
  color: #444444;
}

.input {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
}

.heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;

  color: #686969;
}

.sub-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.408px;
  color: #4b4b4b;
}

.link {
  color: #007aff;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.link:hover {
  color: #3496ff;
}
.form-heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 220% */

  letter-spacing: -0.408px;

  color: #444444;
}
.theme-btn,
.theme-btn-link {
  background: #007aff;
  border: 1px solid #007aff;
  border-radius: 24px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #ffffff;
  padding: 6px 24px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.theme-btn-outline {
  background: transparent;
  border: 1px solid #007aff;
  border-radius: 24px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #007aff;
  padding: 3px 24px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.theme-btn-outline:hover {
  background-color: #007aff;
  color: #fff;
}
.theme-btn:hover {
  background-color: #007bffe7;
  color: #fff;
}

.theme-btn-link:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #007bffea;
}

.mob-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #0d3068;
}

/* Header */
.header-container {
  background-color: #0d3068 !important;
  z-index: 9999;
}

.header {
  display: flex;
  padding: 8px 16px;
  background: #0d3068;
  justify-content: space-between;
}

.header-heading {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
}

.header-text {
  font-weight: 275;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  margin-top: 3px;
  cursor: pointer;
}

.header-right-side {
  display: flex;
}

.credit-coins {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  color: #ffffff;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* //Header */
.search-container {
  position: relative;
  width: 330px;
}
@media (max-width: 561px) {
  .search-container {
    width: 68%;
  }
  .navbar-brand {
    width: 18%;
  }
  .navbar-brand img {
    width: 100%;
  }
  .navbar-toggler.collapsed {
    padding: 4px;
  }
  .navbar-toggler-icon {
    width: 20px;
    height: 20px;
  }
}
.search-container img {
  position: absolute;
  left: 16px;
  top: 25%;
}
.filter-img-container img {
  cursor: pointer;
}
.search-input {
  width: 100%;
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #939393;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(223, 223, 223, 0.06);
  border-radius: 30px;
  padding: 8px 0;
  padding-left: 42px;
  border: none;
}

.view-all {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.408px;
  text-decoration: none;
  white-space: nowrap;
  color: #ff4a39;
}

.view-all:hover {
  color: #ff4a39b9;
}

.categories-box {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(223, 223, 223, 0.06);
  border-radius: 3px;
  /* width: 90%; */
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: scale 0.4s;
}
.categories-box:hover {
  scale: 1.1;
}
.category-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.408px;
  color: #4b4b4b;
  margin: 12px 0;
  text-align: center;
  text-transform: uppercase;
}

.numberOfCandidate {
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.408px;
  color: #eb863b;
  /* margin: 16px 0; */
}

.subcription-box,
.profile-completion-box {
  padding: 18px;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(223, 223, 223, 0.06);
  border-radius: 12px;
  /* width: 45%; */
}

.plan {
  background: #eaeaeb;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  padding: 15px;
  width: 300px;
  cursor: pointer;
}

.plan:hover {
  background: #f2f7ff;
  border: 1px solid #65a4fd;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.plan:active {
  background: #f2f7ff;
  border: 1px solid #65a4fd;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.premium-plan {
  border: 1px solid #fff;
  padding: 1px 12px;
  border-radius: 12px;
  background: #007aff;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  width: 100px;
  letter-spacing: -0.408px;
  background: #ffcb3f;
  border-radius: 17px;
}

.payment-success-box {
  width: 33%;
  margin: auto;
  height: 70vh;
  min-width: 320px;
}

/*  */
.recommended-profile {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(223, 223, 223, 0.06);
  border-radius: 3px;
  padding: 14px;
  margin-bottom: 12px;
  transition: scale 0.4s;
}
.recommended-profile:hover {
  scale: 1.05;
}

.card-heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #454545;
}

.designation {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.408px;
  color: #454545;
}

.profile-availbility {
  font-weight: 600;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.408px;

  color: #d8981c;
}

.card-heding {
  font-weight: 600;
  font-size: 1rem;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #454545;
}

.card-heding img {
  margin-left: 8px;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: none;
}

.card-subHeading {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #444444;
}

.left-scroll {
  height: calc(100vh - 43px - 38px - 19px);
  overflow-y: auto;
}

.profile-info {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.408px;
  color: #444444;
}

.profile-last-seen {
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #021761;
  text-align: right;
}

.match-profile {
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  letter-spacing: -0.408px;
  color: #ff4a39;
  margin-left: 3px;
}

.img-size16 {
  width: 16px;
  height: 16px;
}

.profile-img {
  border-radius: 3px;
  width: 100%;
  /* height: 100%; */
  /* min-height: 260px;
    max-height: 260px; */
  max-height: 160px;
}

.last-seen {
  font-weight: 300;
  letter-spacing: -0.408px;
  color: #444444;
  margin-right: 3px;
}

.save-profile {
  position: absolute;
  bottom: 5px;
  right: 5px;
  /* width: 24px;
    height: 24px; */
}

.verified {
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #007aff;
  margin-left: 6px;
}

.profile-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 220% */

  text-align: center;
  letter-spacing: -0.408px;

  color: #4b4b4b;
  margin-bottom: 8px;
}

.about-profile {
  font-weight: 300;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #444444;
}

.graph-arrow {
  position: absolute;
  left: 45%;
}

.unlocked-profile {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(223, 223, 223, 0.06);
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  margin: 0 6px;
  margin-bottom: 12px;
  transition: scale 0.4s;
}
.unlocked-profile:hover {
  scale: 1.05;
}

.unlocked-status {
  background: #e4f1ff;
  border-radius: 20px;
  padding: 6px 12px;
  text-align: center;
  margin-top: 8px;
  display: inline;
  margin-top: 8px;
}

.unlocked-time {
  font-weight: 400;
  display: inherit;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;

  color: #1d74d3;
}

.hire-status {
  background: #000000;
  opacity: 0.6;
  border-radius: 2px;
  padding: 6px 16px;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.408px;
  color: #ffffff;
  position: absolute;
  top: 6px;
  right: 6px;
}

.profile-match {
  background: #ffffff;
  border-radius: 20px;
  padding: 3px 14px;
  position: absolute;
  bottom: -8px;
}

.match-score {
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: -0.408px;
  color: #062445;
}
.active-navitem {
  background: #00000047;
  margin: -6px 0;
}
.nearMe-box {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(223, 223, 223, 0.06);
  border-radius: 3px;
  padding: 12px;
  margin-bottom: 12px;
  transition: scale 0.4s;
}
.nearMe-box:hover {
  scale: 1.1;
}
.location-img {
  background: #f0f6ff;
  border-radius: 2px;
  width: 100%;
  height: 100%;
}

.location-heading {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.408px;

  color: #494949;
}

/* .unlocked-profile-img {
  width: 100%;
  height: 100%;
  height: 210px;

} */

/* Footer .css */

.footer-container {
  width: 100%;
  /* height: calc(100vh - 93vh); */
  /* height: 60px; */
  bottom: 0;
  position: absolute;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  padding: 16px;
  z-index: 99999;
  max-width: 1200px;
}

.footer {
  height: 40px;
  display: flex;
  align-items: center;
  width: 55%;
}

.header-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 12px;
}

.header-links:hover {
  text-decoration: none;
}

.header-links:hover .menu-label {
  opacity: 0.9;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 40px;
}

.footer-links:hover {
  text-decoration: none;
}
.footer-links p.text-white.menu-label.mt-1 {
  transition: scale 0.1s;
}
.footer-links p.text-white.menu-label.mt-1:hover {
  scale: 1.1;
}
@media screen and (max-width: 767px) {
  .footer-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 0px;
  }

  .footer-links:hover {
    text-decoration: none;
  }
  .unlocked-profile-img {
    height: unset;
  }
}

.mfooter-icons {
  width: 24px;
  height: 24px;
}

.mfooter-social-icons {
  width: 32px;
  height: 32px;
  margin: 8px;
  transition: scale 0.2s;
}
img.mfooter-social-icons:hover {
  scale: 1.2;
}

.mfooter-appstore-icons {
  width: 140px;
  /* height: 32px; */
  margin: 0px 6px;
  transition: scale 0.2s ease-in-out;
}
.mfooter-appstore-icons:hover {
  scale: 1.1;
}

.post-footer {
  position: absolute;
  top: -56px;
}

.img-70 {
  height: 70px;
  width: 70px;
}

.backBar-title {
  font-weight: 700;
  font-size: 18px;
  color: #0d3068;
}

.hashtags-keys {
  display: flex;
  padding: 5px 10px 5px 14px;
  width: 100%;
  overflow-x: auto;
  align-items: center;
}

.hashtags-keys button {
  margin: 0 6px;
}

.message-box {
  padding: 12px 0;
  cursor: pointer;
}

.message-box:hover {
  background-color: var(--themeBg);
}

.message-box-container {
  height: calc(100vh - 72px - 65px);
  background: rgb(255, 255, 255);
  overflow-y: auto;
}

.messages-chat {
  margin-bottom: -70px;
}

.deactive-tags {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #343c44;
  border-radius: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  padding: 3px 24px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.deactive-tags:hover {
  background-color: #007aff;
  color: #ffffff;
}

.post-day {
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;
  letter-spacing: -0.408px;
  color: #444444;
}

.active-tag {
  background-color: #007aff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: #ffffff;
  border-radius: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  padding: 3px 24px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.msg-profile {
  border-radius: 50%;
  height: 42px;
  width: 42px;
}

.msg-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.408px;
  text-transform: capitalize;
  color: #656567;
}

.no_messages {
  background: #4095f1;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  height: 22px;
  width: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #ffffff;
  border-radius: 50%;
}

.filters {
  padding: 10px;
  position: sticky;
  left: -23px;
  background: #e9eff6;
}

.login-container {
  width: 40%;
  min-width: 350px;
  min-height: calc(100vh - 72px - 180px);
  background-color: #fff;
  padding: 14px;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  border-radius: 14px;
}

.login-img {
  width: 300px;
  margin: auto;
  /* height: 280px; */
}

.login-container::-webkit-scrollbar {
  width: 3px;
}

.login {
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.408px;
  width: 150px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.login:hover {
  color: #00244b;
  border-bottom: 2px solid #0d306899;
}

.login-active {
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #0d3068;
  border: none;
  border-bottom: 2px solid rgba(13, 48, 104, 0.6);
  width: 150px;
  background-color: transparent;
  cursor: pointer;
}

.line {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-top: 0;
}

.input-container {
  position: relative;
}

.input-text,
.input-date {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 10px 10px 16px;
  letter-spacing: -0.408px;
  color: #96989a;
  width: 100%;
}
.input-text:focus-visible {
  outline: 1px solid grey !important;
}
.sub-user-box {
  cursor: pointer;
  border-radius: 3px;
  padding: 6px 14px;
}
.sub-user-box:hover {
  background-color: #ffffffa3;
}
.input-date {
  width: 48%;
}

.left-img {
  position: absolute;
  left: 15px;
  top: 30%;
}

.login-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #0d3068;
}

.submenu-bar {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 3px;
}

.mobile_four_digit_verification_code input {
  width: 14%;
  height: 38px;
  font-size: 20px;
  box-shadow: 0px 0px 16px rgba(95, 72, 138, 0.16);
  color: #15101e;
  font-weight: 400;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
}

.resend {
  color: #007aff;
  margin-left: 3px;
  font-weight: 600;
  background-color: transparent;
  border: none;
}

/* Chat */
.chat-area {
  height: calc(100vh - 72px - 65px);
  overflow-y: auto;
  padding-bottom: 0px;
  display: flex;
  align-items: flex-end;
  /* flex-direction: column; */
}

.chat {
  width: 100%;
  /* height: 100%; */
  overflow: auto;
}

.chat::-webkit-scrollbar {
  width: 3px;
  background-color: #0000001a;
}

.chat::-webkit-slider-thumb {
  background-color: #e9be507c;
}

.send-input-box {
  position: absolute;
  width: 97%;
  bottom: 16px;
  display: flex;
}

.send-input-box .send-input {
  background: #ffffff;
  border: 1px solid #83b9d0;
  border-radius: 40px;
  padding: 10px 48px;
  width: 100%;
  height: 100%;
}

.send-input-box .left-img {
  position: absolute;
  left: 16px;
  top: 30%;
}

button.send-btn {
  background-color: transparent;
  border: none;
}

button.send-btn img {
  width: 46px;
  height: 46px;
}

.send-input-box .right-img {
  position: absolute;
  right: 16px;
  top: 30%;
}

.sender-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #35404d;
  text-transform: capitalize;
}

.msg-day {
  padding: 10px 50px;
  font-weight: 400;
  position: absolute;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  background: #e9eff6;
  z-index: 9;
  color: #656567;
}

hr.days-update-line {
  background: #aaaaaa;
  width: 100%;
}

.recieve-msg-box {
  background: #fbfbfb;
  border: 1px solid #e8e8e8;
  border-radius: 0px 10px 10px 10px;
  padding: 16px 12px;
  margin-top: 12px;
  width: 89%;
}

.send-msg-box {
  background: #fbfbfb;
  border: 1px solid #e8e8e8;
  border-radius: 10px 0px 10px 10px;
  width: 89%;
  padding: 16px 12px;
  margin-top: 12px;
}

.sender-img {
  height: 42px;
  width: 42px;
  margin-top: 12px;
}

.r-p-row {
  position: absolute;
  bottom: 8px;
  width: 70%;
  margin: auto;
}

.r-p-row .col-4 {
  text-align: center;
}

.r-p-row .profile-btn {
  /* width: 30px;
    height: 30px; */
  cursor: pointer;
}

.addprofile {
  position: absolute;
  right: 0px;
  /* height: 12px;
    width: 16px; */
  cursor: pointer;
}

.vaccancy-pic-area {
  background-color: var(--themeBg);
  padding: 18px;
}

/* .vaccancy-pic {
    height: 75%;
    width: 75%;
} */

.view-profile {
  position: absolute;
  background: #418ee1;
  border-radius: 20px;
  text-align: center;
  padding: 0px 14px;
  bottom: -11px;
}

.view-profile-link {
  text-decoration: none;
  font-weight: 400;
  font-size: 9px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #ffffff;
}

.hired-designation {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.408px;
  color: #418ee1;
}

.blue-line {
  background: #418ee1;
  border-radius: 3px;
  height: 3px;
  margin: 0;
  width: 60%;
  margin-top: -3px !important;
}

.blue-line-bottom {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(223, 223, 223, 0.06);
  margin: 0;
  margin-top: 8px;
  /* margin-top: -3px !important; */
}

.filter-box,
.add-to-list-box {
  background: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  width: 360px;
  padding: 14px 24px;
  max-height: 450px;
  overflow-y: auto;
  transition: all 1s;
}

.add-to-list-box .text-center.bg-white.sticky-top {
  padding-top: 10px;
  margin-right: -24px;
  margin-left: -24px;
}

.filter-box .space-between.d-flex.sticky-top.bg-white {
  padding-top: 10px;
  margin-right: -24px;
  margin-left: -24px;
}

.filter-box .space-between.d-flex.bg-white.sticky-bottom.mb-n2 {
  margin-left: -24px;
  margin-right: -24px;
  padding: 0 24px;
  padding-bottom: 14px;
  background: #fff;
}

/* .filter-box:hover{
    width: 2000px;
} */
.toggle-img-btn {
  transition: transform 0.5s;
}

#btnControl {
  display: none;
}

#btnControl:checked + .toggle-img-btn > img,
.toggle-img:focus {
  transform: rotate(180deg);
}

.filter-active {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  width: 50%;
  text-align: center;
  letter-spacing: -0.408px;
  color: #007aff;
  cursor: pointer;
  border-bottom: 2px solid #007aff;
  padding-bottom: 6px;
}

.filter {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  width: 50%;
  text-align: center;
  letter-spacing: -0.408px;
  color: #453545;
  cursor: pointer;
}

.toggle-btn {
  background-color: transparent;
  border: none;
  /* transform: rotate(180deg); */
  transition: transform 0.5s;
}

.toggle-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 183% */

  letter-spacing: -0.408px;

  color: #444444;
}

.filter-collapse {
  width: 0%;
  /* height: 100%; */
  /* max-height: 140px; */
  overflow: auto;
  transition: width 2s, height 2s;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
}

.blur-screen-full-area {
  position: fixed;
  width: 100vw;
  left: 0;
  height: 100%;
  z-index: 9999;
  background-color: #00000090;
  bottom: 0;
  padding: 5px;
  z-index: 999999;
}

.fixed-new-post {
  position: fixed;
  right: 100px;
  bottom: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #ffffff;
  background-color: #0d3068;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.fixed-new-post a {
  background-color: transparent;
  border: none;
  padding: 0px;
  color: #ffffff;
  font-weight: bold;
  font-size: 40px;
  border-radius: 32px;
}

.fixed-new-post a:hover {
  text-decoration: none;
  background-color: #053c94;
}
.slot-time-box {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: -0.408px;
  color: #444444;
  /* margin: 5px 12px; */
  margin: 0 12px 12px 0;
  padding: 3px 12px;
}
.close-popup-btn {
  position: absolute;
  right: 12px;
  font-size: 16px;
  color: red;
  top: 12px;
  /* font-size: 14px; */
  z-index: 99;
}
.fixed-new-post:hover {
  text-decoration: none;
  background-color: #053c94;
}

.fixed-new-post a img {
  height: 64px;
  width: 64px;
}

/* Post Vaccancy */

.form-container {
  background-color: #fff;
  padding: 16px;
  border-radius: 14px;
}

.form-container input::-webkit-input-placeholder::after {
  content: "wrerrefd";
}

.applicants-details-container {
  background: #000000cb;
  border-radius: 14px;
  /* background-image: url("./images/Rectangle-scenery.png"); */
  width: 100%;
  max-height: 75vh;
  position: relative;
  overflow-y: auto;
  min-width: 320px;
  padding: 20px;
}

.notification {
  max-height: 75vh;
  overflow-y: auto;
  padding: 14px 10px;
  background-color: #ffffff;
  border-radius: 14px;
  position: absolute;
  top: 62px;
  right: -10px;
  box-shadow: 0 4px 10px #00000066;
  width: 350px;
}

.notification::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.btn-none {
  background-color: transparent;
  border: none;
}

/* .candidates-notification {
} */

.view-notification {
  background: #e2eeff;
  border: 1px solid #99bbeb;
  border-radius: 40px;
  font-weight: 300;
  font-size: 8px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #06325f;
  padding: 0px 18px;
  margin-top: 8px;
}

.second-notification-profile {
  position: absolute;
  top: 8px;
  left: 3px;
}

.third-notification-profile {
  position: absolute;
  top: 16px;
  left: 8px;
}
.msg-notification {
  cursor: pointer;
  padding: 14px 14px 0 14px;
}

.msg-notification:hover {
  background-color: #e9eff6;
  border-radius: 12px;
}

.notification-time-status {
  position: absolute;
  right: 8px;
  top: -16px;
  font-weight: 300;
  font-size: 8px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #656567;
}

.white-circle {
  background: #ffffff;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  cursor: pointer;
  margin: 6px 0;
  border: 1px solid #444;
}

.yellow-circle {
  background: #ffcb3f;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  cursor: pointer;
  margin: 6px 0;
}

.red-circle {
  background: #d93e30;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  cursor: pointer;
  margin: 6px 0;
}

.blue-circle {
  background: #1374df;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  cursor: pointer;
  margin: 6px 0;
}

.candidate-name {
  font-weight: 300;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #ffffff;
  text-transform: capitalize;
}

.font-12 {
  font-size: 12px !important;
}

.font-8 {
  font-size: 8px !important;
}

.transparent-border-btn {
  border: 1px solid #ffffff;
  border-radius: 30px;
  min-width: 60px;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #ffffff;
  padding: 3px 12px;
  /* margin-top: 12px; */
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: alias;
  white-space: nowrap;
}
.free-subs {
  border: 1px solid #adb5bd;
  width: 80px;
  text-align: center;
  border-radius: 12px;
}
.applicants-distance {
  position: sticky;
  bottom: 0px;
}

.distance-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.408px;
  color: #ffffff;
}

.premium {
  background: #ffcb3f;
  border: 1px solid #ffffff;
  border-radius: 17px;
  font-weight: 400;
  font-size: 9px;
  /* line-height: 22px; */
  text-align: center;
  letter-spacing: -0.408px;
  padding: 0px 6px;
  color: #76480b;
  position: absolute;
  bottom: -10px;
}

.opps {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #0d3068;
}

.my-profile-container {
  box-shadow: 0px 4px 10px #00000008;
  border-radius: 14px;
  position: absolute;
  top: 62px;
  right: -18px;
  width: 350px;
  max-height: 70vh;
  z-index: 99999;
  overflow-y: auto;
  box-shadow: 0 4px 10px #00000066;
  cursor: default;
}
/* .my-profile-container::after {
  background: fixed;
  content: "";
  position: fixed;
  bottom: 99%;
  right: 74px;
  top: 62px;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
} */
.my-profile {
  padding: 16px;
  border-radius: 14px;
  background: #ffffff;
}

.profiles-box {
  padding: 12px 0;
}

.my-profile-user {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.user-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  letter-spacing: -0.408px;
  color: #454545;
}

.myProfile-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  letter-spacing: -0.408px;
  color: #454545;
}

.rightPositionTopImg {
  position: absolute;
  top: 12px;
  right: 12px;
}
.edit-profile {
  background: #ffffff;
  border-radius: 14px;
  padding: 16px;
  width: 400px;
  height: 70vh;
  overflow-y: auto;
}
.location-boxes {
  max-height: calc(75vh - 236px);
  overflow-y: auto;
}
.animation {
  animation-name: example;
  animation-duration: 1s;
  transition: height 2s;
  overflow: hidden;
  height: 100%;
}
@keyframes example {
  from {
    height: 0px;
  }
  to {
    height: 144px;
  }
}
.useMyLocation-box {
  padding: 16px;
  margin: 18px 0;
  background-color: transparent;
  border: 1px solid #d2d2d2;
  width: 350px;
  cursor: pointer;
  border-radius: 12px;
}
.useMyLocation-box:hover {
  text-decoration: underline;
}
.credit-coins-container {
  max-height: 65vh;
  overflow-y: auto;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 14px;
  position: absolute;
  top: 62px;
  right: 92px;
  width: 350px;
  padding-top: 16px;
  box-shadow: 0 4px 10px #00000066;
  cursor: default;
}

.days-left {
  background: #fca463;
  border-radius: 17px;
  font-weight: 400;
  font-size: 8px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.profile {
  font-weight: 300;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #656567;
}

.popup-heading {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #656567;
}

.credit-date {
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #686969;
}

.coins-topup {
  width: 350px;
  background: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;
  padding: 12px;
  position: fixed;
}

.topup-range-box {
  background: #f2f7ff;
  border: 1px solid #65a4fd;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
}

input[type="text"]::-webkit-input-placeholder::after {
  content: "*";
  color: tomato;
}

.looking-keys button {
  margin: 4px;
}

.search-events {
  background: #ffffff;
  border-radius: 14px;
  padding: 16px;
  width: 400px;
  height: 70vh;
  overflow-y: auto;
}

.select-input {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 30px;
  padding: 6px 12px;
}

.range-box {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 10px;
}

.range-details {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #00244b;
}

.location-main-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.408px;

  color: #0d3068;
}

.location-all {
  padding-top: 24px;
  padding: 16px;
  max-height: 75vh;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.recent-location {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #686969;
}

.location-name {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.408px;
  text-transform: capitalize;
  color: #393d42;
}

.available-box {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  padding: 8px 16px;
  height: auto;

  -moz-transition: height 1s ease;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;

  transition: height 1s ease;
}

.available-day {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 220% */

  letter-spacing: -0.408px;

  color: #444444;
}

.availavle-slots {
  font-size: 9px;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.details {
  height: 75vh;
  overflow-y: auto;
}

.applicants-availability {
  width: 33%;
  margin: auto;
  background: #fff;
  border-radius: 14px;
  min-width: 320px;
  background: #e9eff6;
  border-radius: 10px;
}

.applicants-availability .available-box {
  margin-bottom: 12px;
}

.post-success-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;

  color: #0d3068;
}

.current-location-box {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

/* Calender */

.calendarViewMob {
  background: #fff;
  box-shadow: -1px 0 4px rgba(33, 32, 37, 0.1);
  border-radius: 32px;
  overflow: hidden;
}

.month-header {
  background-color: #f1f3ff;
  border-radius: 32px 32px 0 0;
  padding: 12px;
  box-shadow: 0 1px 16px rgba(33, 32, 37, 0.08);
}

.calendarHeaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.days.active {
  border-radius: 50%;
  width: 40px;
  text-align: center;
  background-color: #e9be50;
  height: 40px;
}

.days {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding: 11px;
}

.active {
  color: #212025 !important;
}

.calendarHeaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateBoxCalendar {
  border: 1px solid #f0f0f0;
  padding: 0;
  position: relative;
  height: 10vh;
}

.calendarDate {
  position: unset;
  top: 5px;
  left: 0;
  margin-bottom: 0;
  align-items: center;
}

.input-select {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  padding: 9px 12px;
  color: #96989a;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  letter-spacing: -0.408px;
}
.top-sticky-after-header {
  position: sticky;
  top: 92px;
  background: #e9eef6;
  border-bottom: 1px solid #e8f1ff;
  z-index: 999;
}
.right-side-create-subusers {
  background-color: #ffffff85;
  padding: 16px;
  border-radius: 14px;
}

.not-in-lg-header-text {
  display: none;
}

.subcription-section,
.complete-profile-section {
  padding: 1rem;
}
.mt-xs-3 {
  margin-top: 1rem;
}
@media only screen and (max-width: 991px) {
  .me-auto.my-2.my-lg-0.ml-auto.navbar-nav.navbar-nav-scroll {
    max-height: unset !important;
  }

  .navbar-brand {
    /* color: rgb(255, 255, 255); */
    font-weight: bold;
    margin-right: 8px;
    font-size: 24px;
  }

  .header-links {
    width: 100px;
    flex-direction: row;
    justify-content: unset;
    align-items: center;
  }

  .mfooter-icons {
    margin-right: 10px;
    width: 24px !important;
  }

  .btn-none {
    padding: 0;
  }

  nav .item.relative img.pr-2.img-fluid {
    margin-right: 0.5rem;
  }

  .not-in-lg-header-text {
    margin-left: 16px;
    display: block;
  }

  .not-in-lg-header-icon {
    height: 18px;

    width: 18px;
  }

  .credit-coins-container,
  .notification,
  .my-profile-container {
    position: fixed !important;
    top: 62px !important;
    left: 0 !important;
    max-height: 90vh !important;
  }

  .credit-coins-container,
  .notification,
  .my-profile-container {
    position: fixed !important;
    top: 62px !important;
    left: 170px !important;
    max-height: 90vh !important;
  }
}
.candidates-notification.row {
  padding: 0 14px;
}

@media (max-width: 445px) {
  .search-input {
    width: 100%;
  }

  .navbar-brand {
    margin-right: 8px !important;
    font-size: 24px !important;
  }

  .credit-coins-container {
    position: fixed !important;
    top: 62px !important;
    left: 0 !important;
    max-height: 90vh !important;
    width: 100% !important;
  }
}

@media (max-width: 520px) {
  .credit-coins-container,
  .notification,
  .my-profile-container {
    position: fixed !important;
    top: 62px !important;
    left: 0 !important;
    max-height: 90vh !important;
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .mt-xm-3 {
    margin-top: 1rem;
  }
  .fixed-new-post {
    right: 30px;
    bottom: 30px;
  }
}

.fix-chat-header {
  background-color: #0d3068 !important;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
}

.chat-section-box {
  height: 100%;
}

.chat-user-name {
  color: #ffffff;
  font-size: 14px;
}

.chat-back-arrow-header {
  cursor: pointer;
}

.wrapped-message-area {
  height: calc(100vh - 72px - 65px - 45px - 50px);
  overflow-y: auto;
}

/* Login */

.login-section {
  position: relative;
  min-height: calc(100vh - 65px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.login-section .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 42%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.default-form {
  position: relative;
}
.login-section .outer-box {
  position: relative;
  margin-left: 42%;
  width: 58%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-section .login-form {
  margin: 0 auto;
  max-width: 610px;
  width: 100%;
  padding: 0 30px;
}

.login-form h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #202124;
  margin-bottom: 32px;
}
.default-form .form-group > label {
  font-size: 15px;
  line-height: 20px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 10px;
}
.default-form .form-group input[type="text"],
.default-form .form-group input[type="password"],
.default-form .form-group input[type="number"],
.default-form .form-group input[type="email"],
.default-form .form-group input[type="url"],
.default-form .form-group textarea,
.default-form .form-group select {
  position: relative;
  width: 100%;
  display: block;
  height: 42px;
  line-height: 30px;
  padding: 6px 20px;
  font-size: 15px;
  color: #696969;
  background: #f0f5f7;
  border: 1px solid #f0f5f7;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all 300ms ease;
}
.login-form .field-outer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.login-form .field-outer label {
  margin-bottom: 0;
}

.login-form .field-outer .pwd {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #202124;
  white-space: nowrap;
}

.login-form .form-group .theme-btn {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.login-form .bottom-box {
  position: relative;
  padding-top: 12px;
  text-align: center;
}

.login-form .text {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  color: #696969;
  text-align: center;
  margin-top: 5px;
}

.login-form .text a {
  color: #696969;
  font-weight: 500;
}

.login-form .bottom-box .divider {
  position: relative;
  display: block;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
  background: #ffffff00;
  margin: 10px 0 25px;
}

.login-form .bottom-box .divider span {
  position: relative;
  z-index: 9;
}

.login-form .bottom-box .divider:before {
  position: absolute;
  left: 0;
  top: 10px;
  height: 1px;
  width: 100%;
  background: #ecedf2;
  border-radius: 8px;
  content: "";
}

.login-form .bottom-box .divider:after {
  position: absolute;
  left: 50%;
  top: 0px;
  height: 20px;
  width: 60px;
  background: var(--themeBg);
  margin-left: -30px;
  content: "";
}

.login-form .bottom-box .btn-box {
  position: relative;
}

.social-btn-two {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  color: inherit;
  font-size: 14px;
  border-radius: 8px;
  background-color: inherit;
  border: 1px solid #fff;
  font-weight: 500;
  padding: 10px 30px;
  color: #1967d2;
  line-height: 25px;
  margin-bottom: 20px;
}

.social-btn-two i {
  margin-right: 10px;
  font-size: 15px;
}

.social-btn-two.facebook-btn {
  border-color: #3b5998;
  color: #3b5998;
}

.social-btn-two.facebook-btn:hover {
  background-color: #3b5998;
  color: #ffffff;
}

.social-btn-two.google-btn {
  border-color: #dc4d28;
  color: #dc4d28;
}

.social-btn-two.google-btn:hover {
  background-color: #dc4d28;
  color: #ffffff;
}

.myProfile-details-area {
  padding: 12px;
  background: #fbfbfbf0;
}
.edit-profile-input {
  /* width: 14%; */
  font-size: 14px;
  color: #15101e;
  font-weight: 400;
  background: #ffffff;
  border: 1px solid #dfe2e2;
  border-radius: 6px;
  padding: 12px;
}
.edit-profile-input:focus-visible {
  outline: 1px solid grey !important;
}
