@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.jobseeker-container {
  background: #fbf5e8;
}
.jobseeker-btn,
.jobseeker-btn-black,
.jobseeker-btn-dark,
.jobseeker-btn-outline {
  background: #f28b00;
  border: 1px solid #f28b00;
  border-radius: 24px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #ffffff;
  padding: 6px 24px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  text-decoration: none;
}
.jobseeker-btn:hover {
  background-color: #f28900e3;
  color: #ffffff;
}
.jobseeker-btn-outline:hover {
  background-color: #f28900e3;
  color: #ffffff;
}
.jobseeker-btn-outline {
  background-color: #faf4e7;
  color: #8d8d8d;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 4px 6px rgba(223, 223, 223, 0.06);
  border-radius: 40px;
}
.jobseeker-bg-light {
  background-color: #faf4e7;
}
.jobseeker-btn-black {
  background-color: transparent !important;
  color: #444444 !important;
  border: 1px solid #444444;
}
.jobseeker-btn-black:hover {
  background: #444444 !important;
  border-radius: 20px;
  border: 1px solid #fff;
  color: #ffffff !important;
}
.jobseeker-btn-dark {
  background: #000000;
  border-radius: 20px;
  border: 1px solid #444444;
}
.jobseeker-btn-dark:hover {
  background: #000000ea;
  color: #fff;
}
.jobseeker-tag {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #343c44;
  padding: 6px 24px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  text-decoration: none;
}
.unlocked-date {
  background: #fbf5e8;
  border-radius: 12px;
  padding: 6px 12px;
  text-align: center;
  margin-top: 8px;
  margin-top: 8px;
}
.unlocked-time-jobseeker {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #09325f;
}
.recruiter-header-container {
  background-color: #f28b00 !important;
  z-index: 9999;
}
.recruiter-footer-container {
  background-color: #f28b00 !important;
  padding: 30px;
}

.applied-box {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(223, 223, 223, 0.06);
  border-radius: 3px;
  padding: 12px;
  transition: scale 0.4s;
}
.applied-box:hover{
  scale: 1.05;
}
.info-logo {
  background: #fbf5e8;
  border-radius: 12px;
}
.job-requirement {
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #454545;
}
.job-sent-box {
  background: #d5fdd9;
  border-radius: 20px;
  padding: 0px 24px;
}
.job-sent-status {
  font-weight: 400;
  font-size: 9px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #0d3f12;
}

.profile-container {
  padding: 12px;
  border-radius: 50%;
  height: 41px;
  width: 41px;
  border: 0.5px solid #444444;
  display: flex;
  align-items: center;
  justify-content: center;
}
.job-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  letter-spacing: -0.408px;
  text-transform: capitalize;
  color: #444444;
}
.job-for {
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
  text-align: left;
  letter-spacing: -0.408px;

  color: #454545;
}
.card-subheading {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;

  text-align: left;
  letter-spacing: -0.408px;

  color: #444444;
}
.card-answer {
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  text-align: left;

  letter-spacing: -0.408px;

  color: #454545;
}
.card-status-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  text-align: left;
  letter-spacing: -0.408px;

  color: #444444;
}
.jobseeker-jobstatus {
  background: #f28b00;
  border-radius: 3px;
  height: 5px;
  margin: 0;
  width: 60%;
  margin-top: -4px !important;
}
.fade-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #44444481;
}
.jobseeker-view-all {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.408px;
  text-decoration: none;
  white-space: nowrap;
  color: #f09959;
}
.btn-bottom-profile-logo {
  position: absolute;
  font-size: 14px;
  bottom: -15px;
}
.job-by-company {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #ffffff;
}
.job-by-name {
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #ffffff;
}
.posted-by-info-box {
  position: absolute;
  top: 0;
  width: 100px;
  left: 0;
}
.border-50 {
  border-radius: 50% !important;
}
.job-option-bar {
  background: #ffffff;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.unactive {
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.408px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 32px;
}
.option-active {
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #f09959;
  border: none;
  border-bottom: 2px solid #f09959;
  background-color: transparent;
  cursor: pointer;
  margin-right: 32px;
}
.wrapped-content-area {
  min-height: calc(100vh - 78px - 180px) !important;
}
.jobseeker-bg {
  background: #f28b00;
}

.small-btn-outline {
  background: #fffaf6;
  border: 1px solid #f28b00;
  border-radius: 40px;
  font-weight: 300;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #f28b00;
}
.jobseeker-range {
  accent-color: #c17302;
  background-color: #d5fdd9;
  -webkit-appearance: listbox;
  filter: hue-rotate(-12deg);
}
.verify {
  font-weight: 500;
  font-size: 11px;
  line-height: 21px;
  letter-spacing: -0.408px;
  color: #007aff;
}
.completed-status {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.408px;
  color: #1b2c6b;
}
.updated-status {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.408px;
  color: #8d8d8d;
}
.profile-proggress-bar {
  background: #c4c4c450;
  border-radius: 10px;
  width: 100%;
  height: 5px;
  position: relative;
  margin-top: 12px;
}
.proggress-bar {
  position: absolute;
  top: -3px;
  left: 0;
  background: #158420;
  border-radius: 10px;
  height: 10px;
}
.jobseeker-upgrade-popup {
  background: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px;
  padding: 12px;
  width: 350px;
}
.upgrade-heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #f28b00;
}
.jobseeker-profile {
  background-color: #fff;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  width: 420px;
}
.profile-circle {
  background: #8a4918;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.profile-name {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #3f95f3;
}
.profileSelect {
  position: absolute;
  bottom: 5px;
  right: 5px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #c4c4c4;
  height: 20px;
  width: 20px;
}
.mini-heading {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #454545;
}
.job-deployer {
  position: absolute;
  top: 3px;
  left: 0px;
}
.blur-forview {
  height: 100%;
  width: 100%;
  background: #2e2c2c2e;
  border-radius: 12px;
}
.job-logo {
  background-image: url(./Myjob/building1.png),
    linear-gradient(to right, #032544, #1e1c1cc2);
  background-size: cover, contain;
  background-position: center, right bottom;
  background-repeat: no-repeat, no-repeat;
  border-radius: 12px;
}
.job-logo2 {
  background-image: url(./Myjob/building3.png),
    linear-gradient(to right, #032544, #1e1c1cc2);
  background-size: cover, contain;
  background-position: center, right bottom;
  background-repeat: no-repeat, no-repeat;
  border-radius: 12px;
  overflow: hidden;
}
.bottom-progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.time-input {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 2px 0px 0px 2px;
  width: 60px;
  height: 40px;
  text-align: center;
}
.mini-text {
  font-weight: 300;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;

  color: #686969;
}
.ongoing-btn {
  width: 45%;
}

.applied-box-onTimesheet {
  width: 50%;
}
@media (max-width: 776px) {
  .ongoing-btn {
    width: unset;
  }
  .applied-box-onTimesheet {
    width: 100%;
  }
}
.jobseeker-circle {
  background: #f28b00;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  cursor: pointer;
  margin: 6px 0;
}
